@font-face {
  font-family: Sego;
  src: local("segoesc.ttf"), url(../public/segoesc.ttf);
}

*{
  padding: 0;
  margin: 0;
  color: white;
  text-align: center;
}

html{
  background-color: #181818;
}

.topbanner{
  height: 48px;
  width: 100%;
  background-color: #8AE3A8;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.bannertext{
  color: black;
}

.App{
  height: 100%;
  width: 100%;
  background-color: #181818;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="text"]{
  padding: 16px 8px;
  border-radius: 8px;
  border: 1px solid #242424;
  background-color: #181818;
  width: 90%;
  margin: 8px auto;
  text-align: start;
  outline: none;
  font-size: 1em;
}

.imprintlocation{
  position: absolute;
  bottom: 0;
  right: 0;
}

.copyclass{
  background-color: #242424;
  width: 70%;
  height: 50px;
  margin: 0 auto;
  border-radius: 8px;
  display: flex;
}

.copybtn{
  float: right;
  height: 50px;
  width: 17%;
  min-width: 64px;
  border-radius: 0px 8px 8px 0px;
}

.header{
  font-family: "Sego";
  font-size: 3.5em;
}

.copytext{
  flex: 1;
  border: 1px solid #242424;
  padding: 12px;
  background-color: #181818;
  border-radius: 8px 0px 0px 8px;
  text-align: left;
  font-size: 1em;
}

.copytext:focus{
  border: 1px solid #8AE3A8;
}

.error{
  visibility: hidden;
}

.show{
  visibility: visible;
}

button{
  width: 156px;
  height: 48px;
  background-color: #8AE3A8;
  border-radius: 8px;  
  color: black;
  font-size: medium;
  border: none;
}

button:hover{
  border: 1px solid #98FF98;
  background-color: #6cdb92;
  cursor: pointer;
}

button:active{
  background-color: #6cdb92;
  transform: scale(0.98);
}

input[type="text"]:focus{
  border: 1px solid #8AE3A8;
}

.full{
  height: 100vh;
  width: 100vw;
  margin: 0 auto;
  background-color: #181818;
}

.space{
  background-color: #131313;
  padding: 32px 8px;
  width: 50%;
  border-radius: 16px;
  box-shadow: 4px 4px 18px black;
  margin: 0 auto;
}

@media only screen and (max-width: 600px){
  .space{
    background-color: #131313;
    padding: 32px 8px;
    width: 90%;
    border-radius: 16px;
    box-shadow: 4px 4px 18px black;
    margin: 0 auto;
  }
}

@media (orientation: portrait) {
  .space{
    background-color: #131313;
    padding: 32px 8px;
    width: 90%;
    border-radius: 16px;
    box-shadow: 4px 4px 18px black;
    margin: 0 auto;
  }
}